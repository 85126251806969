import type { FormKitNode } from '@formkit/core'
import { getValidationMessages } from '@formkit/validation'

const getErrorNodeNameAndMessage = (node: FormKitNode) => {
  const [firstValidationMessage] = getValidationMessages(node)
  const [errorNode, messages] = firstValidationMessage
  const errorNodeName = errorNode.name
  const errorMessageKey = messages?.[0]?.meta?.messageKey
  return { errorNodeName, errorMessageKey }
}

export const scrollToErrors = (node: FormKitNode) => {
  const { errorNodeName } = getErrorNodeNameAndMessage(node)
  const targetElement = document.getElementsByName(errorNodeName)[0]
  targetElement?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })
}

export const scrollToElementOnErrorValidation = (
  node: FormKitNode,
  validation: string,
  element: HTMLElement
) => {
  const { errorMessageKey } = getErrorNodeNameAndMessage(node)
  if (errorMessageKey === validation) {
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  } else {
    scrollToErrors(node)
  }
}
